import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SubpageHeader from "../../components/subpageHeader"

const PrivacyPolicy = ({ pageContent }) => {
  const lang = "pl"
  return (
    <Layout
      lang={lang}
      translationFO="/cookies-policy/"
      translationEN="/en/cookies-policy/"
    >
      <SEO
        title={
          pageContent.seo.title ? pageContent.seo.title : pageContent.title
        }
        description={pageContent.seo.metaDesc && pageContent.seo.metaDesc}
        image={
          pageContent.seo.opengraphImage &&
          pageContent.seo.opengraphImage.localFile.publicURL
        }
      />
      <SubpageHeader title={pageContent.title} />
      <section className="default-page">
        <div className="container">
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: pageContent.content }}
          />
        </div>
      </section>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        pageContent: wpPage(id: { eq: "cG9zdDo4MzA=" }) {
          seo {
            title
            metaDesc
            opengraphImage {
              localFile {
                publicURL
              }
            }
          }
          content
          title
        }
      }
    `}
    render={data => <PrivacyPolicy {...data} />}
  />
)
